module.exports = [
    {
       "id": "video-1",
       "title": "Experts who teach",
       "videoid": '4iItjoOaBak',
       "frame": ''
    },
    {
        "id": "video-2",
        "title": "Who should consider this program?",
        "videoid": 'Cwq64nnysoQ',
        "frame": ''
     },
     {
        "id": "video-3",
        "title": "Learning Outcomes",
        "videoid": 'CPq4FnbNYL4',
        "frame": ''
     },
 ]