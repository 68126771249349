import helix from './helix'
import Cookies from 'js-cookie';
import Banners from './banners';
import HelixForm from './HelixForm';
import allStartDates from '../../_data/start-dates';
import videoOptions from '../../_data/videos';
import showCollateral from './collateral';

const banners = new Banners();

window.addEventListener('DOMContentLoaded', (e) => {
    // Menu button
    const menuButton = document.querySelector('button.menu');
    if (menuButton) {
        menuButton.addEventListener('click', (e) => {
            document.body.classList.toggle('nav-open')
        });
    }

    document.querySelectorAll('.helixform').forEach((form) => {
        let helixForm = new HelixForm();
        helixForm.validation = {
            "first_name": [{
                type: "required"
            }],
            "last_name": [{
                type: "required"
            }],
            "email": [{
                    type: "required"
                },
                {
                    type: "email"
                },
            ],
            "phone": [{
                    type: "required"
                },
                {
                    type: "phone"
                }
            ],
            "postal_code": [{
                    type: "required"
                },
                {
                    type: "postal_code"
                }
            ],
            "program_code": [{
                type: "required"
            }, ],
        }
        helixForm.initialize(form);
    })


    // Look for program code in the querystring
    // (ie. standalone rfi pages)
    if (window.URLSearchParams) {
        let searchParams = new URLSearchParams(window.location.search);
        let programCode = searchParams.get('program');
        if (programCode != null) {
          document.querySelectorAll('[name="program_code"]').forEach((input) => {
            input.value = programCode;
          });
        }
      }


    /**
     * YouTube video rotate-o-matic
     */

    // Function to get the video for the given day of the month
    function getVideoForDay() {

        const day = new Date().getDate();

        if (day >= 1 && day <= 10) {
            return videoOptions[0]; // Video 1
        } else if (day >= 11 && day <= 20) {
            return videoOptions[1]; // Video 2
        } else if (day >= 21 && day <= 31) {
            return videoOptions[2]; // Video 3
        } else {
            return null; // Handle the case when the day is outside the valid range (optional)
        }
    }

    // Get the video for the current day
    const selectedVideo = getVideoForDay();

    const videoReplaceId = selectedVideo.videoid;
    const replaceTitle = selectedVideo.title;
    const frame = selectedVideo.frame;

    // console.log(`Video ID: ${videoReplaceId}`);
    // console.log(`Title: ${replaceTitle}`);

    //Title Replacer
    var titlesToReplace = document.querySelectorAll('.title-replace');

    titlesToReplace.forEach(function(titleReplace) {
        titleReplace.innerHTML = replaceTitle;
    })

    /**
     * YouTube players
     */
    const ytApiKey = "AIzaSyAMtPQNVdy9rsvzRPjWvam_XYzherM4Poc";
    // go through each video module
    document.querySelectorAll('div.embed').forEach((video) => {


        // get thumbnail from YouTube if none is pre-defined

        if (frame) {
            video.style.backgroundImage = `url(/assets/images/${frame})`;
        } else {
            // get the video id
            let videoId;
            if (video.dataset.rotate == "true") {
                videoId = videoReplaceId;
            } else {
                videoId = video.dataset.videoId;
            };
            // query YouTube API for thumbnail
            let req = new XMLHttpRequest();
            req.addEventListener('load', () => {
                let data = JSON.parse(req.responseText);
                let thumbnailUrl = data.items[0].snippet.thumbnails.standard.url;
                video.style.backgroundImage = "url('" + thumbnailUrl + "')";
            })
            req.open('GET', 'https://www.googleapis.com/youtube/v3/videos?id=' + videoId + '&key=' + ytApiKey + '&part=snippet&fields=items(snippet/thumbnails/standard)');
            req.send();
        }
    });

    // Replace youtube image href and iframe source and transition to iframe on click
    document.querySelectorAll('div.embed a').forEach((link) => {

        document.querySelectorAll('.video-replace').forEach((videoReplacement) => {
            videoReplacement.setAttribute('href', `https://www.youtube.com/watch?v=${videoReplaceId}`);

        });

        link.addEventListener('click', (e) => {
            e.preventDefault();
            let parent = link.getParent('.embed');
            parent.innerHTML = parent.innerHTML.replace(/(<!--|-->)/g, '');

            if (parent.dataset.rotate == "true") {
                parent.innerHTML = parent.innerHTML.replace('VIDEOREPLACE', videoReplaceId);
            };
        })
    });


    // Show collateral download if necessary
    showCollateral();


    // Global start date replacement 
    if (typeof greaterThan == "undefined") var greaterThan = "01/01/2000";
    greaterThan = greaterThan.split('/');
    var greaterThanDate = new Date(greaterThan[2], (greaterThan[0] - 1), greaterThan[1]);
    //var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now = new Date();
    var threshold = 7; // number of days ahead it switches
    for (var i = 0; i < allStartDates.length; i++) {
        var date = allStartDates[i].split('/');
        var newDate = new Date(date[2], (date[0] - 1), date[1]);
        if (newDate.getTime() >= now.getTime() + (threshold * 24 * 60 * 60 * 1000) && newDate >= greaterThanDate) {
            var dateReplacements = document.querySelectorAll('.date-replace')
            dateReplacements.forEach(function(dateReplacement) {
                dateReplacement.innerHTML = "as soon as <strong>" + date[0] + '/' + date[1] + "</strong>";
            })
            break;
        }
    }

});



Node.prototype.getParent = function(selector) {
    var element = this;
    while (element = element.parentElement) {
        if (element.matches(selector)) {
            return element;
            break;
        }
    }
}